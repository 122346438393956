.container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.main {
  max-width: 600px;
  width: 100%;
  padding: 30px 12px 30px 12px;
  animation: fadeIn 0.5s ease;
  gap: 8px;

  display: flex;
  flex-direction: column;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.9);

  display: flex;
  padding: 24px 24px 24px 24px;
  justify-content: center;
  font-size: 24px;
}

.tabs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}
@media screen and (min-width: 600px) {
  .tabs {
    grid-template-columns: 2fr 4fr;
  }
}

.statsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;
  white-space: nowrap;
}

.pastGames {
  align-self: center;
  max-height: 300px;
  overflow-y: scroll;

  padding: 0px 30px 12px 30px;
  border: 2px solid white;
  border-radius: 8px;

  display: grid;
  grid-template-columns: auto auto auto;
  align-items: start;
  justify-content: center;
  gap: 8px;
  text-align: right;
  font-size: 20px;
}
.pastGames .header {
  padding-top: 12px;
  top: 0;
  position: sticky;
  z-index: 1;
  text-align: center;
  background-color: var(--black);
}
.pastGames p:nth-child(3n + 2) {
  text-align: center;
}

.title {
  margin-top: 16px;
  text-align: center;
  font-size: 24px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
}
.value {
  font-size: 36px;
}

.label {
  font-size: 16px;
}
.chart {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
}
.barWrapper {
  position: relative;
}
.bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  gap: 4px;
  transition: width 0.33s ease;
}
.barColor {
  flex: 1;
  background: var(--green);
  border-radius: 2px;
}
.times {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  font-size: 18px;
  margin-bottom: 8px;
}

.exportContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 0px;
}
.export {
  width: 100%;
}
