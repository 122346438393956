.modalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;

  transition: opacity 0.25s ease;
}
.modalWrapper.hidden {
  opacity: 0;
  pointer-events: none;
}

.modal {
  width: 100%;
  max-width: 400px;
  max-height: 100%;
  padding: 24px;
  background-color: var(--gray-1);
  border: 1px solid var(--white);
  border-radius: 4px;
  z-index: 22;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: transform 0.25s ease;
}
.modalWrapper.hidden.animate .modal {
  transform: translateY(36px);
}

.modalBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 21;
  background-color: #0000007f;
}
