.welcome {
  flex: 1;

  width: 100%;
  max-width: 400px;

  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 30px 12px 30px 12px;
  gap: 30px;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
/*
  Whenever the welcome class state changes,
  play fade in animation
*/
.welcome.daily .tabContainer {
  animation: fadeIn 0.1s ease;
}
.welcome.practice .tabContainer {
  animation: fadeIn2 0.1s ease;
}
.welcome.more .tabContainer {
  animation: fadeIn3 0.1s ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.link {
  font-size: 24px;
}

.historicDescription {
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 1;
}

.historicSelect {
  font-size: 16px;
  padding: 2px;
  background-color: transparent;
  border: none;
  border: 1px solid var(--white);
  border-radius: 4px;
  color: var(--white);
  appearance: none;
}

.historicSelect option {
  background-color: var(--black);
  color: var(--white);
}

.historicInput {
  font-size: 16px;
  border: 1px solid var(--white);
  border-radius: 4px;
  background-color: transparent;
  width: min-content;
}
