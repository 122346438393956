.main {
  max-width: 600px;
  padding: 30px 12px 30px 12px;
  animation: fadeIn 0.25s ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
