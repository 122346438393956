.title {
  font-size: 24px;
  text-align: center;
}

.settingsList {
  margin: 4px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
}

.setting {
  display: flex;
  gap: 16px;
  align-items: center;
}
.setting.indented {
  margin-left: 24px;
}
.setting.disabled .label {
  color: var(--gray-6);
}
.label {
  flex-grow: 1;
  user-select: none;
}
.name {
  font-size: 16px;
}
.description {
  font-size: 12px;
}

.hint {
  font-size: 14px;
}
.kofiEmailInput {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.kofiInputGroup {
  display: flex;
  gap: 4px;
}
.email {
  flex: 1;
}
.submit {
  color: var(--white);
  background-color: var(--gray-4);
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 8px;
}
.submit:active {
  transform: translateY(1px);
}
