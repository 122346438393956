.title {
  margin: 0;
  text-align: center;
  font-size: 24px;
}

.new {
  font-size: 12px;
  padding: 4px;
  border-radius: 12px;
  background: var(--gray-4);
}

.overflow {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  height: 600px;
}

.overflow ul {
  padding-left: 20px;
}
