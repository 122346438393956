.tabs {
  display: grid;
  position: relative;
  min-height: 40px;
}
.tabWrapper {
  position: relative;
}
.tab {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  color: var(--white);
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.small .tab {
  font-size: 14px;
}
@media screen and (min-width: 400px) {
  .small .tab {
    font-size: 16px;
  }
}
@media screen and (min-width: 600px) {
  .tab {
    font-size: 24px;
  }
  .small .tab {
    font-size: 18px;
  }
}
.highlight {
  position: absolute;
  z-index: 1;
  user-select: none;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--gray-3);
  transition: transform 0.25s ease;
  border-radius: 8px;
}
