.button {
  color: var(--white);
  background-color: var(--gray-4);
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  padding: 8px 16px;
}
.button.small {
  padding: 4px 8px;
  font-size: 12px;
  height: initial;
}
.button:disabled {
  color: var(--gray-8);
  cursor: not-allowed;
}

.button:active:not(:disabled) {
  transform: translateY(1px);
}
